<template>
  <div class="layout-content">
    <h1>This is an about page</h1>
  </div>
</template>

<script>
import { store } from "../store";
export default {
  mounted() {
    // 切换主题
    store.setTheme("light");
  }
};
</script>

<style lang="less" slot-scope>
</style>
